.triple-spinner-container{
    padding: 0;
    margin: 0;
    height: 100vh;
    opacity: 1;
    background-color: #ffffff;
}
.triple-spinner {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid #1D4ED8;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
}
.triple-spinner::before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: #2563EB;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: #3B82F6;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.75s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
