@tailwind base;

@tailwind components;

@tailwind utilities;

#root{
}

body{
    font-family: 'Open Sans', sans-serif;
}
