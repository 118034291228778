.course-search-bar {
    padding: 0;
    margin: 0.7rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.course-search-bar input {
    padding: 1rem;
    width: 90%;
    background: url("http://kodyrabatowe.wp.pl/img/ico/search_gr.png") top left no-repeat;
}
.course-search-bar i {
    cursor: pointer;
    transition: all .3s ease-out;
}

.course-search-bar i:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all .3s ease-in;
}


.course-search-bar input:focus,
.course-search-bar button:focus{
    outline: none;
}

.courses {
    /*float: right;*/
    color: black;
    /*height: 100vh;*/
    overflow: hidden;
    overflow-y: scroll;
    height: 84.1vh;
    /*border-bottom: 5px solid #EEEEEE;*/
    /*border-right: 5px solid #EEEEEE;*/
}

.course-group, .section-group {
    text-align: center;
    color: black;
    font-size: 1.3rem;
    background-color: #EEEEEE;
}

.course-name, .section-name{
    padding: 0.6rem;
    text-align: start;
    color: black;
    font-size: 1rem;
    background: white;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
}
.course-name span, .section-name span{
    margin-left: auto;
}

.course-name:hover, .section-name:hover {
    cursor: pointer;
    background-color: #EFF6FF;
}

.back-to-courses-button{
    color: #ffffff;
    background-color: #3476EF;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: 200px 0 100px -100px #1D4ED8 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}

.back-to-courses-button:hover{
    box-shadow: 200px 0 100px -100px #3B82F6 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}

/**
 * Main wrapper
 */
.select-search {
    /*padding-right: 3px;*/
    /*--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);*/
    /*box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);*/
    /*padding: 8px;*/
    padding-top: 8px;
    border-radius: 0.375rem;
    position: relative;
    height: 100%;
    /*font-family: 'Nunito Sans', sans-serif;*/
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    width: 100%;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 0 50px 0 26px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    /*font-family: 'Noto Sans', sans-serif;*/
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    width: 100%;
    /*width: 100%;*/
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    /*font-family: 'Noto Sans', sans-serif;*/
    /*font-size: 18px;*/
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 100%;
}

.select-search__option.is-selected {
    background: #3A7FF3;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #EFF6FF;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #3A7FF3;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    /*font-size: 19px;*/
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    background: #eee;
    padding: 10px;
    width: 100%;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #3A7FF3;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    margin-top: 20px;
    height: 100%;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
.success-alert{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}