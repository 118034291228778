.home{
    /*width: 100%;*/
    /*height: 100%;*/
}
/* width */
::-webkit-scrollbar {
    display: none;
    /*width: 10px;*/
}

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*    background: #f1f1f1;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    background: #2563EB;*/
/*    border-radius: 100px;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*    background: #3B82F6;*/
/*}*/
.nice-border{
    border: 5px solid #EEEEEE;
}

.navbar {
    /*background: #3B82F6;*/
    /*box-shadow: 200px 0 100px -100px #1D4ED8 inset, 0 0 0 black;*/
    /*transition: background 2s ease, box-shadow 1s ease;*/
    text-decoration: none;
    height: 6.5vh;
    display: flex;
    background-image: linear-gradient(to right, #1D4ED8, #3B82F6);
}

#campus-btn, #session-btn {
    transition: all .5s ease-in-out;
    margin-top: 0.3rem;
}

#campus-btn, #session-btn {
    color: white;
}

#campus-btn:focus, #session-btn:focus {
    box-shadow: none;
}

#campus-btn:hover, #session-btn:hover {
    transition: all .5s ease-in-out;
    transform: scale(1.1);
}

.ubc-time:hover {
    cursor: default;
}
.welcome-modal{
    background-color:rgba(0, 0, 0, 0.5);
}
.modal-content  {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 ;
    /*border: 10px solid red;*/
    border-radius: 22px;
}
.welcome-modal-title{
    border-radius:  20px 20px 0 0;
    width: 100%;
    color: #fff;
    opacity: 1;
    background: #3B82F6;
    box-shadow: 200px 0 100px -100px #1D4ED8 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}
.welcome-modal-title:hover{
    box-shadow: 200px 0 100px -100px #3B82F6 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}
.okie-button{
    background: #3B82F6;
    box-shadow: 200px 0 100px -100px #1D4ED8 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}
.okie-button:hover{
    box-shadow: 200px 0 100px -100px #3B82F6 inset, 0 0 0 black;
    transition: background 2s ease, box-shadow 1s ease;
}
.split {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.gutter {
    background-color: #e9ecef;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}