.calendar{
    font-family: 'Open Sans', sans-serif;
}
.calendar a{
    pointer-events: none;
}
.rbc-event-content{
    font-size: 1rem;
}
.rbc-events-container{
    width: 100%;
}